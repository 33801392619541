export const channels = [
  {
    uuid: "16fdfefe-e466-4090-bc1a-57c43937f826",
    type: "channel",
    title: "r tv",
    country: "USA",
    provider: 7427,
    logo:"",
    year: 2002
  }
];
