import React  from "react";

import { fetchResources } from "./helpers";

import { useEpg } from "@nessprim/planby";

// Import theme
import { theme } from "./helpers/theme";

// Example of globalStyles
const globalStyles = `
// @import url('https://fonts.googleapis.com/css2?family=Antonio:wght@400;500;600&display=swap');
.planby {
  font-family: "Antonio", system-ui, -apple-system,
    /* Firefox supports this but not yet system-ui */ "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"; /* 2 */
}
`;

export function useApp() {
  const [channels, setChannels] = React.useState([]);
  const [epg, setEpg] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  
  const channelsData = React.useMemo(() => channels, [channels]);
  //const epgData = React.useMemo(() => epg, [epg]);
  const dateObj = new Date();
  let year = dateObj.getFullYear();
  let month = dateObj.getMonth();
  month = ("0" + (month + 1)).slice(-2);
  let date = dateObj.getDate();
  date = ("0" + date).slice(-2);
  
  const timeS = `${year}-${month}-${date}T00:00:00`;
  const { getEpgProps, getLayoutProps } = useEpg({
    channels: channelsData,
    epg: epg,
    dayWidth: 15200,
    sidebarWidth: 0,
    itemHeight: window.innerWidth-100,
    isSidebar: false,
    isTimeline: true,
    isLine: true,
    startDate: timeS,
    isBaseTimeFormat: false,
    isVerticalMode: true,
    isCurrentTime: true,
    theme,
    globalStyles,
  });
  const fetchmyJson = React.useCallback(async () => {
    await fetch("https://epg.guideus.app/fetch")
      .then((response) => response.json())
      .then((data) => {
        setEpg(data);
        setIsLoading(false);
      });
  }, []);
  const handleFetchResources = React.useCallback(async () => {
    setIsLoading(true);
    const {  channels } = await fetchResources();

    //setEpg(epg);
    setChannels(channels);
    //setIsLoading(false);
  }, []);

  React.useEffect(() => {
    handleFetchResources();
    fetchmyJson();
  }, [handleFetchResources,fetchmyJson]);

  return { getEpgProps, getLayoutProps, isLoading };
}
