import { Epg, Layout } from "@nessprim/planby";

// Import hooks
import { useApp } from "./useApp";

// Import components
import { LiveTime } from "./components";


function App() {
  const { isLoading, getEpgProps, getLayoutProps } = useApp();
  
  return (
    <div style={{ height: "100vh", backgroundColor: "#1a202c"}}>
       <div style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
        <div style={{ flex: 1}} >
          <div style={{ padding:10,color: 'red' }}>
            <div style={{display:'flex'}}><img src="./logo.png" width={50} alt="Logo" /></div>
          </div>
        </div>
        <div style={{ flex: 1, paddingRight: 10,marginTop:-50 }} >
        <div style={{ fontSize:14,textAlign:'center',color:'whitesmoke' }} >Guide US TV</div>
          <div style={{fontSize:12, textAlign:'center',color:'whitesmoke' }} >Electronic Program Guide</div>
        </div>
        <div
          style={{ flex: 1, paddingRight: 10 }} >
        </div>
      </div>
   
    <div style={{ height: "82vh", width: "99%" }}>
    <div style={{padding:10,backgroundColor:'#171923',marginTop:20,color: "whitesmoke",fontWeight:'bold',fontSize:12}}>
        Today's Schedule
      </div>
      <Epg isLoading={isLoading} {...getEpgProps()} id="myid">
        <Layout
          {...getLayoutProps()}
          renderCurrentTime={(props) => <LiveTime {...props} />}
          
        />
      </Epg>
    </div>
    </div>
  );
}

export default App;
