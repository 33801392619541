import React , { useRef,useEffect } from "react";

import { CurrentTimeBox, CurrentTimeContent } from "@nessprim/planby";

export function LiveTime({
  isVerticalMode,
  isBaseTimeFormat,
  isRTL,
  time,
  styles,
}) {
  const newTopPosition = styles.position.top + 102;
  const myRef = useRef(null)
  useEffect(()=>{
    myRef.current.scrollIntoView();
  },[])
      
  return (
    <CurrentTimeBox {...styles.position} top={newTopPosition}>
      <CurrentTimeContent
        isVerticalMode={isVerticalMode}
        isBaseTimeFormat={isBaseTimeFormat}
        isRTL={isRTL}
        style={{ color: "red" }}
        ref={myRef}
        id="myscroll"
      >
        {time}
      </CurrentTimeContent>
    </CurrentTimeBox>
  );
}
