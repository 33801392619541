import { channels } from "./channels";
import { epg } from "./epg";

export const fetchChannels = async () =>
  new Promise((res) => setTimeout(() => res(channels), 400));

export const fetchEpg = async () =>
  new Promise((res) => setTimeout(() => res(epg), 500));

export const fetchResources = async () => {
  const epg = await fetchEpg();
  const channels = await fetchChannels();

  return { epg, channels };
};
